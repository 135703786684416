import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import ContentNavigation from "../components/content-navigation"
import {StaticImage} from "gatsby-plugin-image";

export default function ProjektyUnijne({ data }) {
  const seo = {
    title: "Projekty unijne realizowane przez Medicus-Dukla",
    description: "Medicus Dukla realizuje dwa projekty unijne: DDOM(Dzienny Dom Opieki Medycznej) oraz informatyzację przychodni Medicus-Dukla",
    url: "https://medicusdukla.pl/projekty-unijne"
  }

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <section data-g="container">
        <ContentNavigation
          color="blue"
          title="Diagnostyka"
          pages={[
              {title: 'DDOM', slug: '/ddom'},
              {title: 'Informatyzacja', slug: '/projekty-unijne/informatyzacja'},
              {title: 'Wypożyczalnia sprzętu', slug: '/projekty-unijne/wypozyczalnia'},
          ]}
          heading={title => (<h1>Projekty unijne</h1>)}
        />
        <div className="projects-logos">
          <StaticImage className="a-img" src="../images/homepage/loga.png"
                       alt="fundusze europejskie, rzeczpospolita polska, podkarpackie, unia europejska" quality={80} formats={['auto', 'webp', 'avif']}/>
        </div>
      </section>
    </Layout>
  )
}

